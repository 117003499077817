$theme: var(--theme);
/* Color */
$base-color-static: var(--base-color-static);
$base-color: var(--base-color);
$color-lightened-1: var(--color-lightened-1);
$color-lightened-2: var(--color-lightened-2);
$color-lightened-3: var(--color-lightened-3);
$color-lightened-4: var(--color-lightened-4);
$color-lightened-5: var(--color-lightened-5);
$color-lightened-6: var(--color-lightened-6);
$color-lightened-65: var(--color-lightened-65);
$color-lightened-675: var(--color-lightened-675);
$color-lightened-7: var(--color-lightened-7);
$base-end-color-static: var(--base-end-color-static);
$base-end-color: var(--base-end-color);
$base-background-color: var(--base-background-color);
$base-background-color-alert: var(--base-background-color-alert);
$base-color-alert: var(--base-color-alert);
$base-blue-color: var(--base-blue-color);
$base-blue-color-lightened: var(--base-blue-color-lightened);
$base-red-color: var(--base-red-color);
$base-green-color: var(--base-green-color);
$base-green-color-lightened: var(--base-green-color-lightened);
$base-font-color: var(--base-font-color);
$base-font-color-secondary: var(--base-font-color-secondary);
$base-font-color-secondary-half: var(--base-font-color-secondary-half);

/* Box Shadow */

$base-box-shadow-color: var(--base-box-shadow-color);
$base-box-shadow-color-dark: var(--base-box-shadow-color-dark);

$box-shadow: var(--box-shadow);
$box-shadow-top: var(--box-shadow-top);
$box-shadow-right: var(--box-shadow-right);
$box-shadow-bottom: var(--box-shadow-bottom);
$box-shadow-left: var(--box-shadow-left);
$box-shadow-dark: var(--box-shadow-dark);
$box-shadow-darkest: var(--box-shadow-darkest);

/* Border */
$border-radius: var(--border-radius);
$border-radius-hard: var(--border-radius-hard);
$border-radius-hardest: var(--border-radius-hardest);
$border-color: var(--border-color);
$border-color-dark-only: var(--border-color-dark-only);
$border-width: var(--border-width);
$border-width-thin: var(--border-width-thin);
$border-width-thick: var(--border-width-thick);
$border-width-dark-only: var(--border-width-dark-only);

/* Filter */
$base-filter-brightness-5: var(--base-filter-brightness-5);
$base-filter-contrast-1: var(--base-filter-contrast-1);
$base-filter-invert-1: var(--base-filter-invert-1);
$base-filter-contrast-invert-00: var(--base-filter-contrast-invert-00);
$base-filter-contrast-invert-01: var(--base-filter-contrast-invert-01);
$base-filter-contrast-invert-10: var(--base-filter-contrast-invert-10);
$base-filter-contrast-invert-11: var(--base-filter-contrast-invert-11);

/* Components */
$header-background-color: var(--header-background-color);
$collectible-background-color: var(--collectible-background-color);
$collectible-body-background-color: var(--collectible-body-background-color);
$balances-background-color: var(--balances-background-color);
$modal-container-background-color: var(--modal-container-background-color);
$asset-background-color: var(--asset-background-color);
$send-button-border-color: var(--send-button-border-color);
$send-button-background-color: var(--send-button-background-color);
$send-button-tez-border-color: var(--send-button-tez-border-color);
$send-button-tez-background-color: var(--send-button-tez-background-color);
$send-button-tez-icon-color: var(--send-button-tez-icon-color);
$send-button-buy-icon-color: var(--send-button-buy-icon-color);
$send-button-buy-border-color: var(--send-button-buy-border-color);
$send-button-buy-background-color: var(--send-button-buy-background-color);
$collectible-thumbnail-border-radius: var(--collectible-thumbnail-border-radius);
$search-bar-background-color: var(--search-bar-background-color);
$moonpay-iframe-filter: var(--moonpay-iframe-filter);
$dropdown-icon-invert: var(--dropdown-icon-invert);

$button-background-color: var(--button-background-color);
$button-color: var(--button-color);
$button-red-background-color: var(--button-red-background-color);
$button-red-color: var(--button-red-color);
$button-border-color: var(--button-border-color);

$background-expected-color: var(--background-expected-color);
$background-from-color: var(--background-from-color);
$lqd-font-secondary: var(--lqd-font-secondary);
$lqd-font-terinary: var(--lqd-font-terinary);
$lqd-chart-filler-background-color: var(--lqd-chart-filler-background-color);
/* @media */

/* Default Break */
$lower-break: 63em;
$middle-break: 127em;
$upper-break: 255em;

/* Font Break */
$font-break-0: 253em;
$font-break-1: 153em;
$font-break-2: 123em;
$font-break-3: 103em;
$font-break-4: 83em;
$font-break-5: 63em;

/* Layout Break */
$layout-break-0: 253em;
$layout-break-1: 173em;
$layout-break-2: 123em;
$layout-break-3: 83em;
$layout-break-4: 73em;
$layout-break-5: 63em;
$layout-break-6: 43em;

// Offsets
$font-offset-mobile: 1.2;

$compactness: var(--compactness);

$isMobile: var(--is-mobile);